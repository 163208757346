<app-header></app-header>
<div class="main-container">
  <div class="container-fluid">
    <h1 class="page-header util-padding-0 util-margin-0">
      Fees and Special Transfers
    </h1>

    <div class="header-right-side col-sm-10 col-md-10 col-lg-10  util-padding-0 util-margin-0">
      <div *ngIf="criteria?.companyName.length === 0">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <h3 class="util-margin-bottom-5" *ngIf="criteria?.companyName.length > 0"> Company: {{criteria?.companyName}} -
        {{companyId}} </h3>
    </div>

    <div class="header-right-side col-sm-2 col-md-2 col-lg-2 util-margin-top-10">
      <button type="button" class="btn btn-primary " (click)="openFilterOptionModal()">Add Transaction</button>
    </div>
  </div>

  <div *ngIf="!initialLoadDone" id="loading-icon">
    <p class="loading-icon">
      <i class="fa fa-spinner fa-spin fa-4x"></i>
    </p>
  </div>
  <div class="alert alert-info util-margin-top-10" role="alert" *ngIf="showAutoTradedMessage">
    <i class="fa fa-info-circle"></i>
    This client is auto-traded
  </div>

  <div class="modal in" id="selection-modal" style="display:block;" *ngIf="showFilterOptionModal">
    <form #f="ngForm" (ngSubmit)="calculateBalances(transDate, selectedOption)">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <span> Filter Options </span>
          </div>
          <div class="modal-body">
            <div *ngIf="initialLoadDone">
              <div class="panel panel-default">
                <div class="panel-body">
                  <div class="row util-padding-top-20">
                    <div class="col-md-2">
                      <label> Company: </label>
                    </div>
                    <div class="col-md-8">
                      <input class="no-outline" name="companyName" class="no-border" style="width: 500px;"
                        value="{{criteria?.companyName}}" readonly>
                    </div>
                  </div>
                  <div class="row util-padding-top-20">
                    <div class="col-md-2">
                      <label> Transaction Date: </label>
                    </div>
                    <div class="col-md-4">
                      <p-datepicker name="transDate" [(ngModel)]="this.transDate" [disabledDays]="[0,6]"
                        placeholder="mm/dd/yyyy" [readonlyInput]="true"
                        a:placeholder="#{of:formatDate(now, component.pattern)}"
                        (onSelect)="checkIfCurrentOrFutureDate()" [required]="true" [showIcon]="true"></p-datepicker>
                      <p style='color:rgb(160, 34, 34); white-space:nowrap;' *ngIf="futureDatedTransaction">
                        Current/Future dated transaction amounts are not guaranteed</p>

                    </div>
                  </div>
                  <div class="row util-padding-top-20" *ngIf="showDeliveryDateWarning">
                    <div class="col-md-8 util-padding-10 util-margin-left-10 alert alert-info">
                      <p style="font-size: small;">
                        The plans in your selection have transitioned from a prior recordkeeper (Eg: Wells Fargo)
                        and have requested that we maintain their transaction and balance history prior to them starting
                        with Principal.
                        Transactions effective prior to their Delivery Date cannot be entered due to this.
                      </p>
                      <p style="font-size: small;">
                        <span *ngFor="let deliveryDate of blockingDeliveryDates;">
                          Delivery date for {{deliveryDate.planName}} - {{deliveryDate.deliveryDate |
                          date:'MM/dd/yyyy'}}<br>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="row util-padding-top-20">
                    <div class="col-md-2">
                      <label> Select a filter type: </label>
                    </div>
                    <div class="col-md-2">
                      <select id="filterType1" (change)="filterTypeOptions()" name="filter1"
                        [(ngModel)]="selectedOption">
                        <option value="All" [selected]="true"> All </option>
                        <option value="Plan"> Plan </option>
                        <option value="Asset Group"> Asset Group </option>
                      </select>
                    </div>
                  </div>

                  <div class="row util-padding-top-20" *ngIf="displayFilterDropDownBoxPlanLevel">
                    <div class="col-md-2">
                      <label> Select a plan: </label>
                    </div>
                    <div class="col-md-2">
                      <select id="filterType2" name="filter2" (change)="planSelectionEvent()"
                        [(ngModel)]="selectedPlan">
                        <ng-container *ngFor="let planData of planLevelData; let i = index">
                          <option value={{planData.hierarchyLevel.name}},{{planData.hierarchyLevel.id}},{{i}}
                            [selected]="true">
                            {{planData.hierarchyLevel.name}}, ({{planData.hierarchyLevel.id}}) </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>

                  <div class="row util-padding-top-20" *ngIf="displayFilterDropDownBoxAssetGroupLevel">
                    <div class="col-md-2">
                      <label> Select an Asset group: </label>
                    </div>
                    <div class="col-md-2">
                      <select id="filterType3" name="filter3" (change)="assetGroupSelectChangeEvent()"
                        [(ngModel)]="selectedAssetGroup">
                        <ng-container *ngFor="let assetData of assetGroupLevelData; let i = index">
                          <option value={{assetData.hierarchyLevel.name}},{{assetData.hierarchyLevel.id}},{{i}}
                            [selected]="true">
                            {{assetData.hierarchyLevel.name}}, ({{assetData.hierarchyLevel.id}}) </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>

                  <div class=" util-padding-top-20">
                    <tr>
                      <td><input style="vertical-align: super;" type="checkbox" (change)="changeEvent($event)"></td>
                      <td>&nbsp;&nbsp;<label> Select a Source Year (optional): </label></td>
                      <td>
                        <div class="col-md-2">
                          <select id="sourcefilter" name="sourcefilter" [(ngModel)]="selectedYear"
                            [disabled]="toggleBool">
                            <ng-container *ngFor="let year of sourceYears; let i = index">
                              <option value={{year}}> {{year}}</option>
                            </ng-container>
                          </select>
                        </div>
                      </td>
                    </tr>
                  </div>

                </div>
              </div>
            </div>

          </div>

          <div class="modal-footer justify-content-center">
            <button type="submit" [disabled]="!f.valid" class="btn btn-primary"
              (click)="calculateBalances(transDate, selectedOption)" routerLink="details">Continue</button>
            <button type="button" class="btn btn-outline-secondary" (click)="closeFilterOptionModal()">Close</button>

          </div>
        </div>
      </div>
    </form>
  </div>

</div>

<app-fast-transaction-table></app-fast-transaction-table>

<app-footer></app-footer>