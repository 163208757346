import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from './../common.service';
import { CriteriaResponse } from './../selection-criteria/criteria-response';
import { SelectionCriteriaService } from './../selection-criteria/selection-criteria.service';
import { Observable } from 'rxjs';
import { BalancesServiceService } from '../core/services/balances-service.service';
import { DeliveryDateService } from '../core/services/delivery-date.service';
import { DeliveryDate } from '../core/models/DeliveryDate';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationService } from '../core/secured-api/services/authentication.service';
import { SessionStorageService } from 'ngx-webstorage';

export interface FilterType {
  value: string,
  viewValue: string
}

@Component({
    selector: 'app-fast-selection',
    templateUrl: './fast-selection.component.html',
    styleUrls: ['./fast-selection.component.css'],
    standalone: false
})

export class FastSelectionComponent implements OnInit {

  initialLoadDone = false;
  futureDatedTransaction = false;
  todaysDate: Date = new Date();
  transDate: Date;
  localstore: Storage;
  selectedOption: string;
  companyName: string;
  public criteria$: Observable<CriteriaResponse>;
  public criteria: CriteriaResponse;
  public deliveryDate$: Observable<DeliveryDate[]>;
  public minTransactionDate: Date = new Date();
  public companyId: string;
  statusCode: number;
  public planIds: any[] = [];
  public sourceYear;
  public maxTranscationDate: Date = new Date();
  private _selectedTransactionDate: Date;
  selectedPlan: string;
  selectedAssetGroup: string;
  selectedPlanId: string;
  selectedAssetGroupId: string;
  filterType: string;
  displayFilterDropDownBoxPlanLevel = false;
  displayFilterDropDownBoxAssetGroupLevel = false;
  public selectedPlanIds: any[] = [];
  public selectedAssetGroupIds: any[] = [];
  planLevelData;
  assetGroupLevelData;
  planIdFromAssetGroup;
  toggleBool = true;
  selectedYear;
  sourceYears: number[];
  deliveryDates: DeliveryDate[];
  showDeliveryDateWarning: boolean;
  blockingDeliveryDates: DeliveryDate[];
  showAutoTradedMessage: boolean;
  autoTradeMessage: boolean;
  showFilterOptionModal = false;

  public filterPlaceHolder = 'All';
  filterTypes: FilterType[] = [
    { value: 'all-0', viewValue: 'All' },
    { value: 'plan-1', viewValue: 'Plan' },
    { value: 'assetGroup-1', viewValue: 'Asset Group' }
  ];
  constructor(private activatedRoute: ActivatedRoute,
    private selectionCriteriaService: SelectionCriteriaService,
    private router: Router, private common: CommonService,
    public balancesService: BalancesServiceService,
    private deliveryDateService: DeliveryDateService,
    private oidcSecurityService: OidcSecurityService,
    private authService: AuthenticationService,
    private sessionStorage: SessionStorageService
  ) { }

  ngOnInit() {
    if (this.oidcSecurityService.getAccessToken() === undefined) {
      this.oidcSecurityService.authorize();
    }

    this.localstore = window.localStorage;
    this.oidcSecurityService.getIdToken().subscribe((JWT) => {
      this.localstore.setItem("userId", this.authService.decodeJWT(JWT).user_id);
      this.sessionStorage.store("authToken", JWT);
    })

    this.companyId = this.activatedRoute.snapshot.paramMap.get('companyId');
    this.criteria$ = this.selectionCriteriaService.getCriteria(this.companyId);
    this.deliveryDate$ = this.deliveryDateService.getDeiliveryDateByCompany(this.companyId);
    this.criteria$.subscribe(
      value => {
        this.criteria = value;
        this.transDate = new Date(this.criteria.asOfDate);
        this.planLevelData = this.criteria.plans;
        this.deliveryDate$.subscribe(
          deliveryDates => {
            deliveryDates = deliveryDates.filter((deliveryDate: DeliveryDate) => this.planLevelData.map(e => parseInt(e.hierarchyLevel.id, 10)).includes(deliveryDate.planId));
            deliveryDates.forEach((deliveryDate: DeliveryDate) => {
              deliveryDate.planName = this.planLevelData.find((planData) => parseInt(planData.hierarchyLevel.id, 10) === deliveryDate.planId).hierarchyLevel.name;
            });
            this.deliveryDates = deliveryDates;
            this.setNoFilterBackdateLimit();
          }, err => {
            this.setErrorCode(err);
          }
        );
        this.assetGroupLevelData = this.criteria.assetGroups;
        this.sourceYears = this.criteria.years.filter((obj) => obj != null);
        this.selectedYear = this.criteria.years[0];
        this.showAutoTradedMessage = this.criteria.autoTradeFlag;
        this.localstore.setItem('companyName', this.criteria.companyName);
        this.localstore.setItem('autoTradeFlag', String(this.criteria.autoTradeFlag));
      }, err => {
        if (err.status === 404) {
          this.common.setErrorCode(404);
          this.router.navigate(['/error']);
        } else if (err.status === 400) {
          this.common.setErrorCode(400);
          this.router.navigate(['/error']);
        } else if (err.status === 500) {
          this.common.setErrorCode(500);
          this.router.navigate(['/error']);
        } else {
          this.router.navigate(['/error']);
        }
      }
    );

    this.localstore.setItem('companyId', this.companyId);
    this.selectedOption = 'All';
    this.maxTranscationDate.setDate(new Date().getDate() - 1);
    this._selectedTransactionDate = this.maxTranscationDate;
    this.initialLoadDone = true;
  }

  changeEvent(event) {
    if (event.target.checked) {
      this.toggleBool = false;
    } else {
      this.toggleBool = true;
    }
  }

  calculateBalances(transDate, selectedOption) {
    this.transDate = transDate;
    this.selectedOption = selectedOption;
    this.localstore.setItem('transDate', transDate);
    this.localstore.setItem('filterType', selectedOption);
    this.localstore.setItem('companyName', this.criteria.companyName);
    this.localstore.setItem('minTransactionDate', this.minTransactionDate.toDateString());
    this.selectedAssetGroupIds.splice(this.selectedAssetGroupIds.length - 1, 1);
    this.selectedAssetGroup = this.selectedAssetGroupIds.toString();
    this.localstore.setItem('selectedAssetGroup', this.selectedAssetGroup);
    if (this.selectedOption === 'All') {
      let len = this.criteria.plans.length;
      while (len !== 0) {
        this.planIds.push(this.criteria.plans[len - 1].hierarchyLevel.id);
        len = len - 1;
      }
      this.localstore.setItem('planIds', this.planIds.toString());
      this.localstore.setItem('autoTradeFlag', String(this.criteria.autoTradeFlag));
    } else if (this.selectedOption === 'Plan') {
      this.selectedPlanIds = this.selectedPlan.split(',');
      const length = this.selectedPlanIds.length;
      this.planIds.push(this.selectedPlanIds[length - 2]);
      this.localstore.setItem('planIds', this.planIds.toString());
      this.selectedPlanIds.splice(this.selectedPlanIds.length - 1, 1);
      this.selectedPlan = this.selectedPlanIds.toString();
      this.localstore.setItem('selectedPlan', this.selectedPlan);
      this.autoTradeMessage = this.planLevelData.find((planData) => parseInt(planData.hierarchyLevel.id, 10) === parseInt(this.planIds.toString(), 10)).autoTradeFlag;
      this.localstore.setItem('autoTradeFlag', String(this.autoTradeMessage));
    } else if (this.selectedOption === 'Asset Group') {
      this.localstore.setItem('planIds', this.planIdFromAssetGroup.toString());
      const length = this.selectedAssetGroupIds.length;
      this.autoTradeMessage = this.assetGroupLevelData.find((agData) => parseInt(agData.hierarchyLevel.id, 10) === parseInt(this.selectedAssetGroupIds[length - 1], 10)).autoTradeFlag;
      this.localstore.setItem('autoTradeFlag', String(this.autoTradeMessage));
    }
    this.criteria.plans.forEach(plans => {
      this.localstore.setItem(plans.hierarchyLevel.id.toString(), plans.hierarchyLevel.name);
    });
    if (this.toggleBool === false) {
      this.localstore.setItem('sourceYear', this.selectedYear);
      this.localstore.setItem('sourceYearFlag', 'false');
    } else {
      this.localstore.setItem('sourceYear', '0');
      this.localstore.setItem('sourceYearFlag', 'true');
    }
  }

  filterTypeOptions() {
    if (this.selectedOption === 'All') {
      this.displayFilterDropDownBoxPlanLevel = false;
      this.displayFilterDropDownBoxAssetGroupLevel = false;
      this.sourceYears = this.criteria.years.filter((obj) => obj != null);
      this.setNoFilterBackdateLimit();
    } else if (this.selectedOption === 'Plan') {
      this.displayFilterDropDownBoxPlanLevel = true;
      this.displayFilterDropDownBoxAssetGroupLevel = false;
      this.selectedPlan = this.criteria.plans[0].hierarchyLevel.name + ',' + this.criteria.plans[0].hierarchyLevel.id + ',' + 0;
      this.setPlanBackdateLimit(this.criteria.plans[0].hierarchyLevel.id);
      this.sourceYears = this.planLevelData[0].years.filter((obj) => obj != null);
      this.selectedYear = this.sourceYears[0];
    } else if (this.selectedOption === 'Asset Group') {
      this.displayFilterDropDownBoxPlanLevel = false;
      this.displayFilterDropDownBoxAssetGroupLevel = true;
      this.selectedAssetGroup = this.criteria.assetGroups[0].hierarchyLevel.name + ',' + this.criteria.assetGroups[0].hierarchyLevel.id +
        ',' + 0;
      this.setAssetGroupBackdateLimit(this.criteria.assetGroups[0].hierarchyLevel.id);
      this.selectedAssetGroupIds = this.selectedAssetGroup.split(',');
      this.sourceYears = this.assetGroupLevelData[0].years.filter((obj) => obj != null);
      this.selectedYear = this.sourceYears[0];
      const length = this.selectedAssetGroupIds.length;
      this.getPlanIdsByAssetId(this.selectedAssetGroupIds[length - 2]);
    }
  }

  assetGroupSelectChangeEvent() {
    this.selectedAssetGroupIds = this.selectedAssetGroup.split(',');
    const length = this.selectedAssetGroupIds.length;
    const index = this.selectedAssetGroupIds[length - 1];
    this.setAssetGroupBackdateLimit(this.assetGroupLevelData[index].hierarchyLevel.id);
    this.sourceYears = this.assetGroupLevelData[index].years.filter((obj) => obj != null);
    this.getPlanIdsByAssetId(this.selectedAssetGroupIds[length - 2]);
  }

  getPlanIdsByAssetId(assetGroupId) {
    this.balancesService.getPlanIdsByAssetGroupId(assetGroupId)
      .subscribe(data => {
        this.planIdFromAssetGroup = data;
      });
  }

  planSelectionEvent() {
    this.selectedPlanIds = this.selectedPlan.split(',');
    const length = this.selectedPlanIds.length;
    const index = this.selectedPlanIds[length - 1];
    this.setPlanBackdateLimit(this.planLevelData[index].hierarchyLevel.id);
    this.sourceYears = this.planLevelData[index].years.filter((obj) => obj != null);
    this.selectedYear = this.sourceYears[0];
  }

  private setNoFilterBackdateLimit(): void {
    let maxDeliveryDate: Date = new Date('1900/01/01');
    this.showDeliveryDateWarning = false;
    this.blockingDeliveryDates = [];
    this.deliveryDates.forEach((deliveryDate: DeliveryDate) => {
      if (deliveryDate.trnstFlag) {
        if (new Date(deliveryDate.deliveryDate.replace(/-/g, '/')) > maxDeliveryDate) {
          maxDeliveryDate = new Date(deliveryDate.deliveryDate.replace(/-/g, '/'));
        }
        this.showDeliveryDateWarning = true;
        this.blockingDeliveryDates.push(deliveryDate);
      }
    });
    this.minTransactionDate = maxDeliveryDate;
  }

  private setPlanBackdateLimit(planId: number): void {
    let maxDeliveryDate: Date = new Date('1900/01/01');
    this.showDeliveryDateWarning = false;
    this.blockingDeliveryDates = [];
    this.deliveryDates.forEach((deliveryDate: DeliveryDate) => {
      if (deliveryDate.trnstFlag && deliveryDate.planId === planId) {
        if (new Date(deliveryDate.deliveryDate.replace(/-/g, '/')) > maxDeliveryDate) {
          maxDeliveryDate = new Date(deliveryDate.deliveryDate.replace(/-/g, '/'));
        }
        this.showDeliveryDateWarning = true;
        this.blockingDeliveryDates.push(deliveryDate);
      }
    });
    this.minTransactionDate = maxDeliveryDate;
  }

  private setAssetGroupBackdateLimit(assetGroupId: number): void {
    let maxDeliveryDate: Date = new Date('1900/01/01');
    this.showDeliveryDateWarning = false;
    this.blockingDeliveryDates = [];
    this.deliveryDates.forEach((deliveryDate: DeliveryDate) => {
      if (deliveryDate.trnstFlag && deliveryDate.assetGroupId === assetGroupId) {
        if (new Date(deliveryDate.deliveryDate.replace(/-/g, '/')) > maxDeliveryDate) {
          maxDeliveryDate = new Date(deliveryDate.deliveryDate.replace(/-/g, '/'));
        }
        this.showDeliveryDateWarning = true;
        this.blockingDeliveryDates.push(deliveryDate);
      }
    });
    this.minTransactionDate = maxDeliveryDate;
  }

  private setErrorCode(err: any): void {
    if (err.status === 404) {
      this.common.setErrorCode(404);
      this.router.navigate(['/error']);
    } else if (err.status === 400) {
      this.common.setErrorCode(400);
      this.router.navigate(['/error']);
    } else if (err.status === 500) {
      this.common.setErrorCode(500);
      this.router.navigate(['/error']);
    } else {
      this.router.navigate(['/error']);
    }
  }

  checkIfCurrentOrFutureDate() {
    const today = new Date(this.todaysDate.getFullYear(), this.todaysDate.getMonth(), this.todaysDate.getDate());

    // second condition is used to prevent confirmation box from
    // constantly popping up if user picks multiple future dates.
    if (this.transDate >= today && !this.futureDatedTransaction) {
      this.futureDatedTransaction = true;
    } else if (this.transDate < today) {
      this.futureDatedTransaction = false;
    }
  }

  public openFilterOptionModal(): void {
    this.showFilterOptionModal = true;
  }

  public closeFilterOptionModal(): void {
    this.showFilterOptionModal = false;
  }

}
