import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTwoDigitDecimaNumber]',
  standalone: false
})
export class TwoDigitDecimaNumberDirective {
  // Allow decimal numbers and negative values
  private regex = new RegExp(/^\d*\.?\d{0,2}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    // Allow Backspace, tab, end, and home keys
    // eslint:disable-next-line:deprecation
    if (this.specialKeys.indexOf(event.key) !== -1 || ((event.keyCode === 65 ||
      // eslint:disable-next-line: deprecation
      event.keyCode === 86 || event.keyCode === 67 || event.keyCode === 88) &&
      (event.ctrlKey === true || event.metaKey === true)) || (this.specialKeys.indexOf(event.key) !== -1)) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
