import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HierarchyLevel, toHierarchyLevelLabel } from '../core/models/hierarchy-level.enum';
import { NameId } from '../core/models/name-id';
import { CoreState } from '../core/store/core-state/core.state';
import { selectionCriteriaSubmitted } from '../core/store/store.actions';
import { CriteriaResponse, HierarchyLevelDetail } from './criteria-response';
import { SelectionCriteriaService } from './selection-criteria.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-selection-criteria',
    templateUrl: './selection-criteria.component.html',
    styleUrls: ['./selection-criteria.component.css'],
    standalone: false
})
export class SelectionCriteriaComponent implements OnInit {
  public hierarchyLevels: readonly HierarchyLevel[] = [
    HierarchyLevel.AllPlans,
    HierarchyLevel.SinglePlan,
    HierarchyLevel.ContributionGroup,
    HierarchyLevel.AssetGroup
  ];
  public hierarchyLevelDetails: NameId[];
  public hierarchyLevelDetailLabel: string;
  public moneySourceYears: number[] = [];
  public criteria$: Observable<CriteriaResponse>;
  public criteria: CriteriaResponse;
  public companyId: string;
  public selectedHierarchyLevel: HierarchyLevel;
  public selectedHierarchyLevelDetail: NameId;
  public selectedMoneySourceYears: number[];
  public canBeSubmitted = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private selectionCriteriaService: SelectionCriteriaService,
    private router: Router,
    private store: Store<CoreState>) { }

  ngOnInit(): void {
    this.companyId = this.activatedRoute.snapshot.paramMap.get('companyId');

    this.criteria$ = this.selectionCriteriaService.getCriteria(this.companyId);
    this.criteria$.subscribe(value => this.criteria = value);
  }

  public onHierarchyLevelChanged(level: HierarchyLevel): void {
    this.selectedHierarchyLevel = level;
    this.hierarchyLevelDetailLabel = toHierarchyLevelLabel(level);
    this.hierarchyLevelDetails = this.getHierarchyLevelDetails(level);
    this.moneySourceYears = [];
    this.selectedMoneySourceYears = [];
    this.selectedHierarchyLevelDetail = undefined;

    if (level === HierarchyLevel.AllPlans) {
      this.moneySourceYears = this.criteria.years;
      this.canBeSubmitted = true;
    } else {
      this.canBeSubmitted = false;
    }
  }

  public onHierarchyLevelDetailChanged(detail: NameId): void {
    this.selectedHierarchyLevelDetail = detail;
    const detailId = detail ? detail.id : undefined;

    let levelDetails: HierarchyLevelDetail[];
    switch (this.selectedHierarchyLevel) {
      case HierarchyLevel.SinglePlan:
        levelDetails = this.criteria.plans;
        break;
      case HierarchyLevel.AssetGroup:
        levelDetails = this.criteria.assetGroups;
        break;
      case HierarchyLevel.ContributionGroup:
        levelDetails = this.criteria.contributionGroups;
        break;
      case HierarchyLevel.AllPlans:
      default:
        break;
    }

    this.moneySourceYears = levelDetails.find(i => i.hierarchyLevel.id === detailId).years;
    this.canBeSubmitted = true;
  }

  public onMoneySourceYearsChanged(newlySelectedYears: number[]): void {
    this.selectedMoneySourceYears = newlySelectedYears;
  }

  public submitSelectionCriteria(): void {
    this.store.dispatch(selectionCriteriaSubmitted({
      companyId: this.companyId,
      companyName: this.criteria.companyName,
      hierarchyLevel: this.selectedHierarchyLevel,
      hierarchyLevelDetailId: this.selectedHierarchyLevelDetail ? this.selectedHierarchyLevelDetail.id : undefined,
      years: this.selectedMoneySourceYears.length > 0 ? this.selectedMoneySourceYears : undefined
    }));
    this.router.navigate(['details'], { relativeTo: this.activatedRoute });
  }

  private getHierarchyLevelDetails(level: HierarchyLevel): NameId[] {
    switch (level) {
      case HierarchyLevel.AssetGroup:
        return this.criteria.assetGroups.map(i => i.hierarchyLevel);
      case HierarchyLevel.ContributionGroup:
        return this.criteria.contributionGroups.map(i => i.hierarchyLevel);
      case HierarchyLevel.SinglePlan:
        return this.criteria.plans.map(i => i.hierarchyLevel);
      case HierarchyLevel.AllPlans:
      default:
    }
  }
}
