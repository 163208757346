import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ErrorHandlerService } from '../errors/error-handler.service';
import { AuthenticationService } from './authentication.service';
import { SessionStorageService } from 'ngx-webstorage';
import {RunMode} from '../../../../environments/run-modes.enum';
@Injectable({
  providedIn: 'root'
})
export class RestService {
  localStorage: Storage;
  public constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService,
    private sessionStorage: SessionStorageService
  ) {
    this.localStorage = window.localStorage;
  }

  url: string;

  public get<T>(path: string): Observable<T> {
    if (environment.runMode === RunMode.Production ) {
      const tokenRes: string = this.sessionStorage.retrieve('authToken');
      if (!tokenRes) {
        return this.firstApiCall(path, null, 'get');
      } else {
        return this.apiCall(path, null, 'get');
      }
    } else if (environment.runMode === RunMode.LocalMode ) {
      return this.apiCall(path, null, 'get');
    }
  }

  public post<T>(path: string, body: any): Observable<T> {
    return this.apiCall(path, body, 'post');
  }

  public put<T>(path: string, body: any): Observable<T> {
    return this.apiCall(path, body, 'put');
  }

  public delete<T>(path: string): Observable<T> {
    return this.apiCall(path, null, 'delete');
  }

  private getValidHttpParams(url: string, body: any, httpHeaders: HttpHeaders): any {
    return [url, body, { headers: httpHeaders }].filter((param) => !!param);
  }

  private apiCall(path: string, body: any, methodType: string): any {
    if (environment.runMode === RunMode.LocalMode ) {
      this.url = `${environment.apiBaseUrl}/${path}`;
      const params: any = this.getValidHttpParams(this.url, body, null);

      return this.http[methodType](...params);
    } else {
      const tokenRes: string = this.sessionStorage.retrieve('authToken');
      const userId: string = this.localStorage.getItem('userId');
      this.url = `${environment.apiBaseUrl}/${path}`;
      const headers = {
        Authorization: `Bearer ${tokenRes}`,
        'Business-Context': `{"webId": "${userId}"}`,
        'Access-Control-Allow-Origin': '*'
      }
      const httpHeaders: HttpHeaders = new HttpHeaders(headers);
      const params: any = this.getValidHttpParams(this.url, body, httpHeaders);

      return this.http[methodType](...params);
    }
  }

  private firstApiCall<T>(path: string, body: any, methodType: string): Observable<T> {
    const userId: string = this.localStorage.getItem('userId');

    return this.authenticationService.token$
      .pipe(
        flatMap((tokenResponse): Observable<T> => {
          const headers = {
            Authorization: `Bearer ${tokenResponse}`,
            'Business-Context': `{"webId": "${userId}"}`,
          }
          const httpHeaders: HttpHeaders = new HttpHeaders(headers);          const url = `${environment.apiBaseUrl}/${path}`;
          const params: any = this.getValidHttpParams(url, body, httpHeaders);
          return this.http[methodType]<T>(...params);
        }),
        catchError((error) => {
          throw this.errorHandlerService.returnSecuredApiError(`Error making ${methodType.toUpperCase()} request for path: ${path}`, error);
        })
      );
  }


}
