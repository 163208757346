import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RestService } from './services/rest.service';

@NgModule({ declarations: [], imports: [CommonModule], providers: [
        RestService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class SecuredApiModule {
}
