import {Component, Input, OnInit} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-warning-alert-modal',
    templateUrl: './warning-alert-modal.component.html',
    styleUrls: [
        './warning-alert-modal.component.scss'
    ],
    standalone: false
})
export class WarningAlertModalComponent  implements OnInit {
  @Input() effectiveDate: Date;
  @Output() notify = new EventEmitter();

  ngOnInit(): void {
  }
}
