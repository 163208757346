import { Component, OnInit } from '@angular/core';
import { Constants } from './constants';
import { interval } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/core/secured-api/services/authentication.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router, ActivatedRoute } from "@angular/router"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: false
})
export class AppComponent implements OnInit {

  securityServiceUrl = '/rnq/services/rnqsecurityservices-v2/token/enrollment/inside/create';
  title = 'FeesAndSpecialTransfers';
  name = '';
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    public oidcSecurityService: OidcSecurityService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  constants = new Constants();
  tokenBody: any;
  tokenStatus = false;
  timeOutFlag = false;
  callCounter = 0;

  ngOnInit() {
    this.oidcSecurityService.checkAuth()
      .subscribe();
  }

  // eslint:disable-next-line
  subscribe = interval(3300000).subscribe(() => {
    this.timeOutFlag = true;
    if (this.timeOutFlag) {
      document.getElementById('timeoutModalButton').click();
      this.subscribe.unsubscribe();
    }
  });
  // eslint:disable-next-line
  logout = interval(3600000).subscribe(() => {
    document.getElementById('tsm-header-log-out').click();
  });
}
