<div *ngIf="loadDistributionModalComponent">
  <app-load-deduction-modal (notify)="closeModal()" (valueChange)="valueChange($event)"
    [effectiveDate]="transDateString" [transactionType]="selectedTransaction"
    [masterParticipantDataList]="masterParticipantDataList"></app-load-deduction-modal>
</div>
<div *ngIf="displayMissingTransactionTypeModal">
  <app-warning-alert-modal (notify)="closeWarningModal()"></app-warning-alert-modal>
</div>
<app-header></app-header>
<div *ngIf="!_dataReady">
  <app-loading-spinner></app-loading-spinner>
</div>
<div *ngIf="_dataReady" class="main-container">
  <div class="container-fluid">

    <h1 class="page-header util-padding-0 util-margin-0">
      <span>Fees and Special Transfers</span>
    </h1>
  </div>
  <div class="alert alert-info util-margin-top-10" role="alert" *ngIf="showAutoTradeFlag">
    <i class="fa fa-info-circle"></i>
    This client is auto-traded
  </div>
  <div class="container util-margin-top-20">
    <div class="panel panel-default">
      <div class="panel-heading" *ngIf="!inViewMode">
        <span> Company Information </span>
      </div>
      <div class="panel-heading" *ngIf="inViewMode">
        <span> Transaction Information </span>
      </div>
      <div class="panel-body">
        <div class="row util-padding-top-15" *ngIf="inViewMode">
          <div class="col-md-1">
            <label> Company: </label>
          </div>
          <div class="col-md-3">
            <span> {{this.companyName}} </span>
          </div>
          <div class="col-md-2">
            <label> Transaction Date: </label>
          </div>
          <div class="col-md-2"><span> {{transDate | date : 'MM/dd/yyyy'}}</span> </div>
          <div class="col-md-2">
            <label> Transaction Type: </label>
          </div>
          <div class="col-md-2"><span>{{transType}} </span> </div>
        </div>

        <div class="row util-padding-top-15" *ngIf="!inViewMode">
          <div class="col-md-1">
            <label> Company: </label>
          </div>
          <div class="col-md-4">
            <span> {{this.companyName}} </span>
          </div>
          <div class="col-md-2">
            <label> Transaction Date: </label>
          </div>
          <div>
            <div class="col-md-4" style="max-width: 250px;">
              <p-datepicker name="transDate" (onSelect)="checkIfCurrentOrFutureDate()" [(ngModel)]="transDateString"
                [disabledDays]="[0,6]" placeholder="mm/dd/yyyy" [required]="true" [showIcon]="true"
                class="transDate"></p-datepicker>
              <p style='color:rgb(160,34,34); white-space:nowrap; margin-left:-190px;' *ngIf="futureDatedTransaction">
                Current/Future dated transaction amounts are not guaranteed</p>
            </div>
          </div>

          <div class="col-md-1" style="min-width: 230;">
            <button class="btn btn-primary" data-toggle="modal" data-target="#my-modal"
              style="min-width: 180px; margin-top:-15px;"> Reload Balances </button>
          </div>
        </div>
        <div class="row util-padding-top-15">
          <div class="col-md-1" style="min-width: fit-content;" *ngIf="!inViewMode">
            <label> Filter type: </label>
          </div>
          <div class="col-md-2" *ngIf="!inViewMode">
            <span> {{this.selectedOption}} </span>
          </div>
          <div class="col-md-1" style="min-width: fit-content;"
            *ngIf="isSourceYearRequired && isPlanNameRequired== false && isAssetGroupNameRequired==false">
            <label> Source year: </label>
          </div>
          <div class="col-md-6" style="min-width: fit-content;"
            *ngIf="isSourceYearRequired && isPlanNameRequired== false && isAssetGroupNameRequired==false">
            <span>{{this.sourceYear}}</span>
          </div>

          <div class="col-md-2" *ngIf="isPlanNameRequired">
            <label> Plan selection: </label>
          </div>
          <div class="col-md-6" style="min-width: fit-content;" *ngIf="isPlanNameRequired">
            <span> {{this.selectedPlan}} </span>
          </div>

          <div class="col-md-2" style="min-width: fit-content;" *ngIf="isAssetGroupNameRequired">
            <label> Asset Group selection: </label>
          </div>
          <div class="col-md-6" *ngIf="isAssetGroupNameRequired">
            <span> {{this.selectedAssetGroup}} </span>
          </div>
        </div>
        <div class="row util-padding-top-15"
          *ngIf="isSourceYearRequired && (isPlanNameRequired || isAssetGroupNameRequired)">
          <div class="col-md-1" style="min-width: fit-content;">
            <label> Source year: </label>
          </div>
          <div class="col-md-6" style="min-width: fit-content;">
            <span>{{this.sourceYear}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row" style="display: flex;">
      <div class="col-md-6" *ngIf="!inViewMode">
        <div class="panel panel-default" style="min-height: 280px;">
          <div class="panel-heading">
            <span> Step 1 </span>
          </div>
          <div class="panel-body">
            <span><label> Select a transaction type: &nbsp;</label> &nbsp;&nbsp;</span>
            <span>
              <mat-form-field [floatLabel]="'never'">
                <!--ngAfterViewChecked() is automatically called when a selection is made. 
                    Keep that method in mind to understand automatic updates that occur in Step 4-->
                <mat-select [(ngModel)]="selectedTransaction" (selectionChange)="setTransactionTypeProperties()"
                  name="transaction" [placeholder]="transactionPlaceHolder">
                  <ng-template ngFor let-item [ngForOf]="transactions">
                    <mat-option *ngIf="item.enabled == true" [value]="item.viewValue">
                      <span>{{item.viewValue}}</span>
                    </mat-option>
                  </ng-template>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="!inViewMode">
        <div class="panel panel-default embed-responsive-item">
          <div class="panel-heading">
            <span> Step 2 </span>
          </div>
          <div class="panel-body">
            <div class="row">
              <label class="col-md-6" style="width: 200px;"> Exclude money types: </label>
              <p class="col-md-6" *ngIf="flagShowNumberOfMoneyExcluded" style="width: 350px; text-align: right;"> Number
                of money types excluded: {{numberOFMoneyExcluded}} </p>
            </div>
            <div class="panel" style="height: 110px; overflow: auto;">
              <div class="panel-body">
                <table>
                  <tr *ngFor="let moneyTypeRow of this.upperPanelData?.moneyTypeDetailList">
                    <td style="vertical-align: text-bottom;"> <input type="checkbox"
                        id="{{'moneyType#'+moneyTypeRow.moneyTypeId}}" [value]="moneyTypeRow.moneyTypeId"
                        (change)="setMoneyTypeIds($event)"> </td>
                    <td class="util-padding-left-10"> {{ moneyTypeRow.moneyName }} </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-md-6">
              <button class="btn btn-primary" (click)="showModalApplyFilterMoney()" id="fundFilterApplyButton"> Apply
                Filter </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6" *ngIf="!inViewMode">
        <div class="panel panel-default" style="height: 400px;">
          <div class="panel-heading embed-responsive-item">
            <span> Step 3 </span>
          </div>
          <div class="panel-body" *ngIf="selectedTransaction == 'Fund Transfer'">
            <div class="row">
              <label class="col-md-6"> Source Fund Selection:</label>
            </div>
            <div class="panel fundTransferPanel">
              <div class="panel-body">
                <table>
                  <tr *ngFor="let fundTypeRow of this.upperPanelData?.fundDetailList">
                    <td style="vertical-align: text-bottom;"> <input type="radio" name="sourceFundSelection"
                        id="{{'sourcefund#'+fundTypeRow.fundId}}" [value]="fundTypeRow.fundId"
                        (change)="setFundForTransfer($event, 'source', fundTypeRow.fundName, fundTypeRow.fundId)"> </td>
                    <td class="util-padding-left-10"> {{ fundTypeRow.fundName }} ({{fundTypeRow.fundId}}) </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row">
              <label class="col-md-6"> Target Fund Selection:</label>
            </div>
            <div class="panel fundTransferPanel">
              <div class="panel-body">
                <table>
                  <tr *ngFor="let fundTypeRow of this.upperPanelData?.fundDetailList">
                    <td style="vertical-align: text-bottom;"> <input type="radio" name="targetFundSelection"
                        id="{{'targetfund#'+fundTypeRow.fundId}}" [value]="fundTypeRow.fundId"
                        (change)="setFundForTransfer($event, 'target', fundTypeRow.fundName, fundTypeRow.fundId)"> </td>
                    <td class="util-padding-left-10"> {{ fundTypeRow.fundName }} ({{fundTypeRow.fundId}}) </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="panel-body" *ngIf="selectedTransaction != 'Fund Transfer'">
            <div class="row">
              <label class="col-md-6"> Exclude funds: </label>
              <p class="col-md-6" *ngIf="flagShowNumberOfFundsExcluded"> Number of funds excluded:
                {{numberOFFundsExcluded}} </p>
            </div>
            <div class="panel"
              style="max-height: 200px; overflow: auto; border-width: 1px; border-color: #f1f1f1; border-style:solid;">
              <div class="panel-body">
                <table>
                  <tr *ngFor="let fundTypeRow of this.upperPanelData?.fundDetailList">
                    <td style="vertical-align: text-bottom;"> <input type="checkbox" id="{{'fund#'+fundTypeRow.fundId}}"
                        [value]="fundTypeRow.fundId" (change)="setFundIds($event)"> </td>
                    <td class="util-padding-left-10"> {{ fundTypeRow.fundName }} ({{fundTypeRow.fundId}}) </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-md-6" style="padding-top: 9px;">
              <button class="btn btn-primary" id="fundFilterApplyButton" (click)="showModalApplyFilterFund()"> Apply
                Filter </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="!inViewMode">
        <div class="panel panel-default" style="height: 400px;">
          <div class="panel-heading">
            <span> Step 4 </span>
          </div>
          <div class="panel-body" [ngClass]="{ 'disabled': selectedTransaction == 'Quiet Record' }">
            <span><label> Select a deduction entry method: &nbsp; </label>&nbsp;&nbsp;&nbsp;</span>
            <mat-form-field [floatLabel]="'never'">
              <mat-select [(ngModel)]="selectedEntryMethod" name="method" [placeholder]="entryMethodPlaceHolder"
                (selectionChange)="refreshTableOnEntryMethodChange()">
                <ng-container *ngFor="let method of entryMethods">
                  <mat-option *ngIf="method.enabled == true" [value]="method.viewValue">
                    <span class="mat-select-dropdown"> {{method.viewValue}} </span>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
            <div *ngIf="selectedTransaction != 'Quiet Record'">
              <div *ngIf="selectedEntryMethod == '$ Entry'">
                <table class="table">
                  <tr style="padding-top: 15px;">
                    <td>
                      <input type="radio" name="inputs" id="radioPercentParticipant" [checked]="true"
                        (click)="checkedPercentagePerParticipant()"
                        onclick="document.getElementById('percentParticipant').disabled = false;
                                                          document.getElementById('dollarAccount').disabled = true;
                                                          document.getElementById('dollarAccount').value = null;
                                                          document.getElementById('dollarParticipant').disabled = true;
                                                          document.getElementById('dollarParticipant').value = null;
                                                          document.getElementById('amountForAllParticipant').value = null;
                                                          document.getElementById('amountForAllParticipant').disabled = true;" style="margin-top: 10px;">
                    </td>
                    <td>
                      <div class="input-group input-group-sm" style="width:100%;">
                        <input type="text" [(ngModel)]="percentagePerParticipant" class="form-control"
                          id="percentParticipant" style="width:100%;" appFiveDigitDecimaNumber>
                      </div>
                    </td>
                    <td class="util-padding-left-5">
                      <span> % per Participant </span>
                    </td>
                  </tr>
                  <tr style="padding-top: 15px;">
                    <td>
                      <input type="radio" name="inputs" id="radioAmountForAllParticipant" [checked]="false"
                        (click)="checkedAmountForAllParticipant()" onclick="document.getElementById('percentParticipant').disabled = true;
                                               document.getElementById('dollarAccount').disabled = true;
                                               document.getElementById('dollarAccount').value = null;
                                               document.getElementById('dollarParticipant').disabled = true;
                                               document.getElementById('dollarParticipant').value = null;
                                               document.getElementById('amountForAllParticipant').value = null;
                                               document.getElementById('amountForAllParticipant').disabled = false;
                                               document.getElementById('percentParticipant').value = null;"
                        style="margin-top: 10px;">
                    </td>
                    <td>
                      <div class="input-group input-group-sm" style="margin-top: 10px;">
                        <span class="input-group-addon">$</span>
                        <input type="text" [(ngModel)]="amountToDivideBetweenAllparticipant" disabled
                          class="form-control" id="amountForAllParticipant" style="width:100%;" appTwoDigitDecimaNumber>
                      </div>
                    </td>
                    <td class="util-padding-left-5" style="margin-top: 10px;">
                      <span> pro rated Amount </span>
                    </td>
                  </tr>
                  <tr style="padding-top: 15px;">
                    <td>
                      <input type="radio" name="inputs" id="radioDollarAccount" [checked]="false"
                        (click)="checkedAmountPerAccount()" onclick="document.getElementById('percentParticipant').disabled = true;
                                                   document.getElementById('percentParticipant').value = null;
                                                   document.getElementById('dollarAccount').disabled = false;
                                                   document.getElementById('dollarParticipant').disabled = true;
                                                   document.getElementById('dollarParticipant').value = null;
                                                   document.getElementById('amountForAllParticipant').value = null;
                                                   document.getElementById('amountForAllParticipant').disabled = true;"
                        style="margin-top: 10px;">
                    </td>
                    <td>
                      <div class="input-group input-group-sm" style="margin-top: 10px;">
                        <span class="input-group-addon">$</span>
                        <input type="text" id="dollarAccount" [(ngModel)]="amountPerAccount" disabled
                          class="form-control" appTwoDigitDecimaNumber>
                      </div>
                    </td>
                    <td class="util-padding-left-5" style="margin-top: 10px;">
                      <span> per Account </span>
                    </td>
                  </tr>
                  <tr style="padding-top: 15px;">
                    <td>
                      <input type="radio" name="inputs" id="radioDollarParticipant" checked="false"
                        (click)="checkedAmountPerParticipant()" onclick="document.getElementById('percentParticipant').disabled = true;
                                                  document.getElementById('percentParticipant').value = null;
                                                  document.getElementById('dollarAccount').disabled = true;
                                                  document.getElementById('dollarAccount').value = null;
                                                  document.getElementById('dollarParticipant').disabled = false;
                                                  document.getElementById('amountForAllParticipant').value = null;
                                                  document.getElementById('amountForAllParticipant').disabled = true;"
                        style="margin-top: 10px;">
                    </td>
                    <td>
                      <div class="input-group input-group-sm" style="margin-top: 10px;">
                        <span class="input-group-addon">$</span>
                        <input type="text" id="dollarParticipant" [(ngModel)]="amountPerParticipant" disabled
                          class="form-control" appTwoDigitDecimaNumber>
                      </div>
                    </td>
                    <td class="util-padding-left-5" style="margin-top: 10px;">
                      <span> per Participant </span>
                    </td>
                  </tr>
                  <tr style="padding-top: 15px;">
                    <td>
                      <input type="checkbox" [(ngModel)]="isPartialDeductionChecked" id="partialDeductionCheckBox"
                        disabled>
                    </td>
                    <td class="util-padding-left-5" style="margin-top: 10px;">
                      <span> Do not deduct partial amount </span>
                    </td>
                  </tr>
                </table>
                <div class="row">
                  <div class="col-md-6">
                    <button class="btn btn-primary" (click)="getSelectedCalculateBalance()"> Calculate Balances
                    </button>
                  </div>
                  <div class="col-md-6">
                    <button class="btn btn-primary" id="exceptionReport" disabled
                      (click)="exportExcelWithExceptionReport()"> Exception Report </button>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedEntryMethod == '% Entry'">
                <table class="table">
                  <tr style="padding-top: 15px;">
                    <td>
                      <input type="radio" name="inputs" id="radioPercentParticipant" [checked]="true"
                        (click)="checkedPercentagePerParticipant()"
                        onclick="document.getElementById('percentParticipant').disabled = false;
                                                          document.getElementById('dollarAccount').disabled = true;
                                                          document.getElementById('dollarAccount').value = null;
                                                          document.getElementById('dollarParticipant').disabled = true;
                                                          document.getElementById('dollarParticipant').value = null;
                                                          document.getElementById('amountForAllParticipant').value = null;
                                                          document.getElementById('amountForAllParticipant').disabled = true;" style="margin-top: 10px;">
                    </td>
                    <td>
                      <div class="input-group input-group-sm" style="width:100%;">
                        <input type="text" [(ngModel)]="percentagePerParticipant" class="form-control"
                          id="percentParticipant" style="width:100%;" appFiveDigitDecimaNumber>
                      </div>
                    </td>
                    <td class="util-padding-left-5">
                      <span> % per Participant </span>
                    </td>
                  </tr>
                </table>
                <div class="row" style="margin-top: 10em;">
                  <div class="col-md-6">
                    <button class="btn btn-primary" (click)="getSelectedCalculateBalance()"> Calculate Balances
                    </button>
                  </div>
                  <div class="col-md-6">
                    <button class="btn btn-primary" id="exceptionReport" disabled
                      (click)="exportExcelWithExceptionReport()"> Exception Report </button>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedEntryMethod == 'Upload'">
                <table class="table">
                  <tr style="padding-top: 15px;">
                    <td>
                      <div class="input-group input-group-sm" style="width:100%;">
                        <a href="/assets/FAST_Load_Deduction_Template.xlsx"
                          style="padding-left: 10px; padding-bottom: 5px;">Download Template</a><br>
                      </div>
                    </td>
                  </tr>
                  <tr style="padding-top: 15px;">
                    <td>
                      <p style="padding-bottom: 5px;">*Note for accounts with more than 3 money types, please add to
                        spreadsheet.</p>
                    </td>
                  <tr style="padding-top: 15px;">
                    <td>
                      <button (click)="popupLoadDistributionModal()" class="btn btn-primary">Upload File</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="float: right;" *ngIf='!inViewMode'>
      <button class="btn btn-primary" id="saveTransaction" [disabled]='isDisable' (click)="saveTransaction()"> Submit
        Transaction </button>
      <button class="btn btn-primary-alt" id="printTransaction" style="margin:10px" (click)="exportexcel()"> Export to
        Excel </button>
      <button class="btn btn-danger" (click)="refresh()"> Refresh </button> &nbsp;
      <button routerLink="/company/{{this.companyId}}" type="button" class="btn btn-primary">Back to previous
        page</button>
    </div>
    <div class="row" *ngIf="!inViewMode">
      <div class="col-md-12">
        <br>
        <app-disclaimer></app-disclaimer>
        <p class="alert alert-warning alert-icon" role="alert" *ngIf="futureDatedTransaction"
          style="color:rgb(160, 34, 34); margin-top:-20px;">
          <b>Warning!</b> This is a current/future dated transaction. The following balances are based on the last
          business day and any deduction
          amounts cannot be guaranteed until the process date of the transaction.
        </p>
        <div class="table-collapse">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-left"></th>
                  <th class="text-left">Participant Name</th>
                  <th class="text-left">Cash Value</th>
                  <th class="text-left">Vested Cash Value</th>
                  <th class="text-left">Interest Accrued</th>
                  <th
                    *ngIf="selectedEntryMethod == '% Entry' && selectedTransaction != 'Fund Transfer'; else fundTransfer_percentMoved"
                    class="text-left">Deduction
                    Percent (%)</th>
                  <ng-template #fundTransfer_percentMoved>
                    <th *ngIf="selectedTransaction == 'Fund Transfer'; else deduction_column" class="text-left">Percent
                      Moved</th>
                  </ng-template>
                  <ng-template #deduction_column>
                    <th class="text-left">Deductions</th>
                    <th class="text-left">Deductions With Interest</th>
                  </ng-template>
                </tr>
              </thead>
              <tbody *ngIf="balanceUpdatedFlag">
                <ng-container *ngFor="let participantData of participantDatalist; let i = index">
                  <tr>
                    <td>&nbsp; &nbsp;
                      <span class="collapse-button">
                        <!-- The input ID "caret-rotate-1" can be changed to any unique ID; just ensure that the label FOR is also updated to match! -->
                        <input type="checkbox" id="caret-rotate-{{participantData.empId}}" data-toggle="collapse"
                          attr.data-target=".js-caret-rotate{{participantData.empId}}">
                        <label for="caret-rotate-{{participantData.empId}}"></label>
                      </span>
                    </td>
                    <td class="text-left" id="name{{participantData.empId}}">{{ participantData.empName }}</td>
                    <td class="text-left">{{ participantData.endCashBal | number : '1.2-2'}}</td>
                    <td class="text-left">{{ participantData.vestedCasBal | number : '1.2-2'}}</td>
                    <td class="text-left">{{ participantData.interestAccrued | number : '1.2-2'}}</td>
                    <td class="text-left">
                      <input *ngIf="(selectedEntryMethod == '$ Entry' || selectedEntryMethod == 'Upload') ||
                                          (!excludingMoneyTypes_showLabel_notInputBox &&
                                          !excludingFunds_showLabel_notInputBox)"
                        [(ngModel)]="participantData.deduction" type="text" appTwoDigitDecimaNumber
                        (keyup)="manualDeductionFromParticipanltLevel($event.target.id, $event.target.value, $event.key)"
                        (change)="manualDeductionFromParticipanltLevel($event.target.id, $event.target.value, $event.key);convertDeductionAmountToTillTwoDecimal($event.target.id)"
                        id="{{participantData.empId}}" placeholder="0.00"
                        [readonly]="selectedTransaction == 'Fund Transfer'">
                      <p *ngIf="selectedEntryMethod == '% Entry' &&
                                        (excludingMoneyTypes_showLabel_notInputBox ||
                                        excludingFunds_showLabel_notInputBox)">
                        See fund level for deductions
                      </p>
                      <div *ngIf="(participantData.deduction > 5.00)">{{alertMessageQRecord}}</div>
                      <div id="id{{participantData.empId}}" style="display: none;">Please select a valid <br>
                        transaction type</div>
                    </td>

                    <td *ngIf="selectedEntryMethod != '% Entry'" class="text-left"
                      id="participant{{participantData.empId}}">{{ participantData.deductionWithInterest | number :
                      '1.2-2'}} </td>
                  </tr>


                  <ng-container *ngFor="let accountData of participantData.accountBalances;">
                    <tr class=" collapse-row js-caret-rotate{{participantData.empId}} collapse" aria-expanded="true">
                      <td class="text-left"></td>
                      <td class="text-left" id="name{{participantData.empId}},{{accountData.accountId}}"
                        style="padding-left: 35px;">{{ accountData.accountName }} ({{ accountData.accountId}}) </td>
                      <td class="text-left">{{ accountData.endCashBal | number : '1.2-2'}}</td>
                      <td class="text-left">{{ accountData.vestedCasBal | number : '1.2-2'}}</td>
                      <td class="text-left">{{ accountData.interestAccrued | number : '1.2-2'}}</td>
                      <td class="text-left">
                        <input *ngIf="(selectedEntryMethod == '$ Entry' || selectedEntryMethod == 'Upload') ||
                                                  (!excludingMoneyTypes_showLabel_notInputBox &&
                                                  !excludingFunds_showLabel_notInputBox)" type="text"
                          [(ngModel)]="accountData.deduction" appTwoDigitDecimaNumber
                          id="{{participantData.empId}},{{accountData.accountId}}"
                          (keyup)="manualDeductionFromAccountLevel($event.target.id, $event.target.value, $event.key)"
                          (change)="manualDeductionFromAccountLevel($event.target.id, $event.target.value, $event.key);convertDeductionAmountToTillTwoDecimal($event.target.id)"
                          [readonly]="selectedTransaction == 'Fund Transfer'" placeholder="0.00">
                        <p *ngIf="selectedEntryMethod == '% Entry' &&
                                                (excludingMoneyTypes_showLabel_notInputBox ||
                                                excludingFunds_showLabel_notInputBox)">
                          See fund level for deductions
                        </p>
                        <div id="id{{participantData.empId}},{{accountData.accountId}}" style="color:red;"
                          style="display: none;">Please select a valid <br> transaction type</div>
                      </td>
                      <td *ngIf="selectedEntryMethod != '% Entry'" class="text-left"
                        id="account{{participantData.empId}},{{accountData.accountId}}"> {{
                        accountData.deductionWithInterest | number : '1.2-2' }}</td>
                    </tr>

                    <ng-container *ngFor="let moneyData of accountData.moneyTypeBalances;">
                      <tr class=" warning collapse-row js-caret-rotate{{participantData.empId}} collapse"
                        aria-expanded="true">
                        <td class="text-left"></td>
                        <td id="name{{participantData.empId}},{{accountData.accountId}},{{moneyData.moneyTypeId}}"
                          style="padding-left: 70px;">{{ moneyData.moneyTypeName }}</td>
                        <td>{{ moneyData.endCashBal | number : '1.2-2'}}</td>
                        <td>{{ moneyData.vestedCasBal | number : '1.2-2'}}</td>
                        <td>{{ moneyData.interestAccrued | number : '1.2-2'}}</td>
                        <td><input *ngIf="selectedEntryMethod == '$ Entry' ||
                                        (!excludingMoneyTypes_showLabel_notInputBox &&
                                        !excludingFunds_showLabel_notInputBox)" type="text"
                            [(ngModel)]="moneyData.deduction" appTwoDigitDecimaNumber placeholder="0.00"
                            (keyup)="manualDeductionFromMoneyTypeLevel($event.target.id, $event.target.value, $event.key)"
                            (change)="manualDeductionFromMoneyTypeLevel($event.target.id, $event.target.value, $event.key);convertDeductionAmountToTillTwoDecimal($event.target.id)"
                            id="{{participantData.empId}},{{accountData.accountId}},{{moneyData.moneyTypeId}}"
                            [readonly]="selectedTransaction == 'Fund Transfer'">
                          <p *ngIf="selectedEntryMethod == '% Entry' &&
                                            (excludingMoneyTypes_showLabel_notInputBox ||
                                            excludingFunds_showLabel_notInputBox)">
                            See fund level for deductions
                          </p>
                          <div id="id{{participantData.empId}},{{accountData.accountId}},{{moneyData.moneyTypeId}}"
                            style="display: none;">Please select a valid <br> transaction type</div>
                        </td>
                        <td *ngIf="selectedEntryMethod != '% Entry'" class="text-left"
                          id="moneyType{{participantData.empId}},{{accountData.accountId}},{{moneyData.moneyTypeId}}">
                          0.00</td>
                      </tr>

                      <ng-container *ngFor="let fundData of moneyData.fundBalances;">
                        <tr class=" bg-light-blue collapse-row js-caret-rotate{{participantData.empId}} collapse"
                          aria-expanded="true">
                          <td></td>
                          <td
                            id="name{{participantData.empId}},{{accountData.accountId}},{{moneyData.moneyTypeId}},{{fundData.fundId}}"
                            style="padding-left: 105px;">{{ fundData.fundName }} ({{fundData.fundId}})</td>
                          <td>{{ fundData.endCashBal | number : '1.2-2'}}</td>
                          <td>{{ fundData.vestedCasBal | number : '1.2-2'}}</td>
                          <td>{{ fundData.interestAccrued | number : '1.2-2'}}</td>
                          <td><input type="text" [(ngModel)]="fundData.deduction" appTwoDigitDecimaNumber
                              placeholder="0.00"
                              (keyup)="manualDeductionFromFundTypeLevel($event.target.id, $event.target.value, $event.key)"
                              (change)="manualDeductionFromFundTypeLevel($event.target.id, $event.target.value, $event.key);convertDeductionAmountToTillTwoDecimal($event.target.id)"
                              id="{{participantData.empId}},{{accountData.accountId}},{{moneyData.moneyTypeId}},{{fundData.fundId}}"
                              [readonly]="selectedTransaction == 'Fund Transfer'">
                            <div
                              id="id{{participantData.empId}},{{accountData.accountId}},{{moneyData.moneyTypeId}},{{fundData.fundId}}"
                              style="display: none;">Please select a valid <br> transaction type</div>
                          </td>
                          <td *ngIf="selectedEntryMethod != '% Entry'" class="text-left"
                            id="fund{{participantData.empId}},{{accountData.accountId}},{{moneyData.moneyTypeId}},{{fundData.fundId}}">
                            {{fundData.deductionWithInterest | number : '1.2-2'}}</td>
                        </tr>

                      </ng-container>
                    </ng-container>

                  </ng-container>

                </ng-container>
                <tr *ngIf="selectedEntryMethod == '$ Entry' || selectedEntryMethod == 'Upload'">
                  <td></td>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-left" id="totalCompanyDeductions"> {{ totalDeductionAmount | number : '1.2-2'}} </td>
                  <td class="text-left" id="totalCompanyDeductionsWithInterest">{{ totalDeductionWithInterestAmount |
                    number : '1.2-2'}}</td>
                </tr>
              </tbody>
            </table>

            <div *ngIf="!balanceUpdatedFlag">
              <p style="text-align-last: center;"><b>The balances for the chosen date are not available. Please select
                  another date.</b></p>
            </div>
            <div *ngIf="participantDatalist && participantDatalist?.length === 0">
              <p style="text-align-last: center;">
                <b>No participants with non-zero balances are available to be displayed.</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="inViewMode" id="transactionBreakdown">
      <app-fast-transaction-breakdown [transactionToView]="transId" [companyName]="companyName" [transDate]="transDate"
        [transType]="transType" [approvalStatus]="approvalStatus">
      </app-fast-transaction-breakdown>
    </div>
  </div>
  <!-- </div> -->
  <!-- modal start -->
  <div class="modal fade" id="my-modal" tabindex="-1" role="dialog" aria-labelledby="my-modal-label">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
          <h2 class="modal-title" id="my-modal-label">Reload Balances</h2>
        </div>
        <div class="modal-body">
          Would you like to reload the balances to reflect the change in transaction date?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal"
            (click)="reloadPreviousTransDate()">NO</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="reloadBalances()">YES</button>
        </div>
      </div>
    </div>
  </div>
  <!-- modal end -->
</div>
<app-footer></app-footer>


<div class="modal fade" id="quietRecordApprovalModal" style="text-align: center;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4> <b> {{approvalText}} </b> </h4>
      </div>
      <div class="modal-body" style="text-align: center;">
        <button style="margin:10px" routerLink="/company/{{this.companyId}}" type="button" class="btn btn-primary"
          data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="quietRecordFailureModal" style="text-align: center;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4> <b> The action taken for this quiet record has failed. Please contact IT. </b> </h4>
      </div>
      <div class="modal-body" style="text-align: center;">
        <button style="margin:10px" routerLink="/company/{{this.companyId}}" type="button" class="btn btn-primary"
          data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="fundTransferErrorModal" style="text-align: center;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4> <b>{{errorMessageForFundTransfer}} </b> </h4>
      </div>
      <div class="modal-body" style="text-align: center;">
        <button style="margin:10px" type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="saveTransactionValidate" tabindex="-1" role="dialog" aria-labelledby="my-modal-label">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h4> <b> No deductions are present in the table. Please provide some valid deductions. </b> </h4>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="saveTransactionSuccess" style="text-align: center;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4> <b> Transaction saved successfully</b> </h4>
      </div>
      <div class="modal-body" style="text-align: center;">
        <button style="margin:10px" routerLink="/company/{{this.companyId}}" type="button" class="btn btn-primary"
          data-dismiss="modal">Ok</button>
        <button class="btn btn-primary-alt" id="printTransaction" style="margin:10px" (click)="exportexcel()"> Export to
          Excel </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="saveTransactionFailure" tabindex="-1" role="dialog" aria-labelledby="my-modal-label">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h4><b>Transaction insertion failed. </b></h4>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="saveTransactionEmail" tabindex="-1" role="dialog" aria-labelledby="my-modal-label">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h4><b>The deductions have been submitted successfully!</b></h4>
      </div>
      <div class="modal-body">
        <p> An email would be shortly sent to you which will inform you about the final status of the transaction. </p>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="applyFundFilterModal" tabindex="-1" role="dialog" aria-labelledby="my-modal-label">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h2 class="modal-title" id="my-modal-label">Apply Filters</h2>
      </div>
      <div class="modal-body">
        Applying these fund filters will clear out all the deductions. Please click on Ok to continue and cancel to not
        apply the filters.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancelButtonClickAndRestoreFunds()">
          Cancel </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="filterFundsEvent()"> Ok </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="applyMoneyFilterModal" tabindex="-1" role="dialog" aria-labelledby="my-modal-label">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h2 class="modal-title" id="my-modal-label">Apply Filters</h2>
      </div>
      <div class="modal-body">
        Applying these money type filters will clear out all the deductions. Please click on Ok to continue and cancel
        to not apply the filters.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancelButtonClickAndRestoreMoney()">
          Cancel </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="filterMoneyTypesEvent()"> Ok
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="exceptionMessageModal" tabindex="-1" role="dialog" aria-labelledby="my-modal-label">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h4> <b> There were {{exceptionList.length}} calculation exceptions. Please click on the Exceptions Report
            button to view them. </b> </h4>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="transTypeValidationModal" tabindex="-1" role="dialog" aria-labelledby="my-modal-label">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <h4> <b> {{alertMessage}} </b> </h4>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="accountScheduleDate" tabindex="-1" role="dialog" aria-labelledby="my-modal-label">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-body1">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
          <h4> <b> Distribution Process </b> </h4>
          The following accounts do not have a valid scheduled distribution setting on the specified transaction date.
          If you wish to continue to process this distribution ,
          click ok. You may view this transaction by looking at the transaction screen and you will not be able to see
          this distribution on the distribution screen.
          <br>List participants:<br> {{participantNameAndAccountID}}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal"
          (click)="saveDistributionTransaction()">OK</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="selectTransaction" tabindex="-1" role="dialog" aria-labelledby="my-modal-label">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <br>
        Please note that changing the transaction type will require the page to be refreshed and you will lose the data
        that you have already entered.
        Please click Ok to select the new transaction type and click Cancel to remain with the existing selection.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="modalOptionNoButtonAction()"> Cancel
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="resetDeductionsForStep4()"> OK
        </button>
      </div>
    </div>
  </div>
</div>

<button id="hiddenButtonValidation" data-toggle="modal" data-target="#saveTransactionValidate"
  style="display: none"></button>
<button id="hiddenButtonSuccess" data-toggle="modal" data-target="#saveTransactionSuccess"
  style="display: none"></button>
<button id="hiddenButtonFailure" data-toggle="modal" data-target="#saveTransactionFailure"
  style="display: none"></button>
<button id="hiddenButtonEmail" data-toggle="modal" data-target="#saveTransactionEmail" style="display: none"></button>
<button id="hiddenButtonFundFilters" data-toggle="modal" data-target="#applyFundFilterModal"
  style="display: none"></button>
<button id="hiddenButtonMoneyFilters" data-toggle="modal" data-target="#applyMoneyFilterModal"
  style="display: none"></button>
<button id="hiddenButtonException" data-toggle="modal" data-target="#exceptionMessageModal"
  style="display: none"></button>
<button id="hiddenButtonTransValidation" data-toggle="modal" data-target="#transTypeValidationModal"
  style="display: none;"></button>
<button id="hiddentButtonForAccountScheduleDate" data-toggle="modal" data-target="#accountScheduleDate"
  style="display: none"></button>
<button id="selectTransactionTypeAlertBox" data-toggle="modal" data-target="#selectTransaction"
  style="display: none"></button>
<button id="hiddenButtonFundTransfer" data-toggle="modal" data-target="#fundTransferErrorModal"
  style="display: none"></button>