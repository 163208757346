import { Component, OnInit } from '@angular/core';
import { FastTransactionService } from '../core/services/fast-transaction.service';

export interface Transaction {
  value: string;
  viewValue: string;
}

export interface EditInfo {
  transId: string;
  transDate: string;
  transType: string;
  companyName: string;
  companyId: string
}

@Component({
    selector: 'app-fast-data-update',
    templateUrl: './fast-data-update.component.html',
    styleUrls: ['./fast-data-update.component.css'],
    standalone: false
})

export class FastDataUpdateComponent implements OnInit {
  editInfo: EditInfo;
  selectedType: string;

  isLoading = false;
  isSuccessful = false;
  transactions: Transaction[] = [
    { value: 'fee-0', viewValue: 'Fee' },
    { value: 'balancetransfer-1', viewValue: 'Balance Transfer' },
    { value: 'distribution-2', viewValue: 'Distribution' },
    { value: 'forfeiture-4', viewValue: 'Forfeiture' }
  ];

  constructor(
    private fastTransactionService: FastTransactionService,
  ) { }

  ngOnInit() {
    this.editInfo = JSON.parse(window.localStorage.getItem('editInfo'))
    this.selectedType = this.editInfo.transType
  }

  saveTransaction(event) {
    if (this.selectedType == this.editInfo.transType && event.flag) return
    if (event.data.length == 0) {
      document.getElementById('hiddenButtonAlert').click();
      return
    }
    const srceType = this.typeShortName(this.editInfo.transType)
    const destType = this.typeShortName(this.selectedType)
    const NoChangeXE = event.flag // && (srceType != 'D' && destType != 'D') && (srceType != 'F' && destType != 'F')
    const data = {
      transDate: this.editInfo.transDate,
      companyId: this.editInfo.companyId,
      entryMethod: "$",
      srceType,
      destType,
      noChangeXE: NoChangeXE,
      employeeBalanceList: event.data
    }
    this.isLoading = true
    this.fastTransactionService.updateFastTransactions(this.editInfo.transId, data)
      .subscribe(
        res => {
          if (res) {
            this.isLoading = false;
            document.getElementById('hiddenButtonSuccess').click();
          }
        },
        err => {
          if (err.status === 504) {
            document.getElementById('hiddenButtonEmail').click();
          } else {
            document.getElementById('hiddenButtonFailure').click();
          }
          this.isLoading = false;
        }
      );
  }

  typeShortName(type) {
    switch (type) {
      case "Fee":
        return "E"
      case "Balance Transfer":
        return "X"
      case "Distribution":
        return "D"
      case "Forfeiture":
        return "F"
    }
  }
}
