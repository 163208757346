import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {RestService} from '../secured-api/services/rest.service';
import {FastTransactionMapper} from '../../fast-transaction-table/fast-transaction-mapper';
import {Router} from "@angular/router";
import {CommonService} from "../../common.service";
import { QuietRecordStatus } from '../models/quietRecord.enum';

@Injectable({
  providedIn: 'root'
})
export class FastTransactionService {
  constructor(private restService: RestService,
              private fastTransactionMapper: FastTransactionMapper,
              private router: Router,
              private common: CommonService) {}

  public transactionList: any;

  public getFastTransactions(companyId: string): any {
    return this.restService.get(`company/${companyId}/FAST/transactions`)
      .pipe(map(i => this.fastTransactionMapper.toFastTransactionResponse(i)));
  }

  public updateFastTransactions(transactionId, transJSON): any {
    return this.restService.put<any>(`FAST/transaction/${transactionId}`, transJSON)
  }

  public approveQuietRecord(transactionId, transactionDate, company): any {
    const body = {
      transDate: transactionDate,
      companyId: company,
      entryMethod: "$",
      srceType: "Q",
      destType: "Q",
      noChangeXE: true
    }

    return this.restService.post(`transaction/reviewQuietRecord/itransact/${transactionId}/approved/${QuietRecordStatus.Approved}`, body)
  }

  public denyQuietRecord(transactionId, transactionDate, company): any {
    const body = {
      transDate: transactionDate,
      companyId: company,
      entryMethod: "$",
      srceType: "Q",
      destType: "Q",
      noChangeXE: true
    }
// build kickoff
    return this.restService.post(`transaction/reviewQuietRecord/itransact/${transactionId}/approved/${QuietRecordStatus.Denied}`, body)
  }

  public getFastDeleteEligible(): any {
    return this.restService.get(`deleteTran`);
  }

  public getUserRecord(): any {
    return this.restService.get('user/foxUserRecord');
  }

  public deleteTransaction(transactionId, type, companyId) {
    if (type == 'Distribution') {
      return this.restService.delete<any>(`FAST/distribution/${companyId}/${transactionId}`);
    }
    else if(type == 'Forfeiture'){
      return this.restService.delete<any>(`FAST/forfeiture/${companyId}/${transactionId}`)
    }
    return this.restService.delete<any>(`FAST/transaction/${companyId}/${type}/${transactionId}`);
  }
}
