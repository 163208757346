import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RunMode } from '../../../environments/run-modes.enum';
import { CompanyDetails } from '../models/company-details';
import { HierarchyLevel } from '../models/hierarchy-level.enum';
import { SelectionCriteria } from '../models/selection-criteria';
import { RestService } from '../secured-api/services/rest.service';

const baseUri = {
  [HierarchyLevel.AssetGroup]: '/assetGroups',
  [HierarchyLevel.ContributionGroup]: '/contributionGroups',
  [HierarchyLevel.SinglePlan]: '/plans',
  [HierarchyLevel.AllPlans]: ''
};

@Injectable({
  providedIn: 'root',
})
export class DetailsService {
  constructor(private restService: RestService) {
  }

  public getDetails(selectionCriteria: SelectionCriteria): Observable<any> {
    if (environment.runMode === RunMode.Production) {
      const years = this.getSelectionCriteriaYears(selectionCriteria);
      const hierarchyLevelPath = this.getHierarchyLevelPath(selectionCriteria);
      return this.restService.get<CompanyDetails>(`companies/${selectionCriteria.companyId}${hierarchyLevelPath}/fundDetails${years}`);
    } else {
      return of({
        participants: [{
          name: 'Participant 1',
          id: 1,
          accounts: [{
            name: 'Account 1',
            id: 1,
            moneyTypes: [{
              name: 'Money Type 1',
              id: 1,
              funds: [{
                name: 'Fund 1',
                id: 1
              }]
            }]
          },
          {
            name: 'Account 2',
            id: 2,
            moneyTypes: [{
              name: 'Money Type 2',
              id: 2,
              funds: [{
                name: 'Fund 2',
                id: 2
              }]
            }]
          }]
        }]
      } as CompanyDetails).pipe(delay(2000));
    }
  }

  public getSelectionCriteriaYears(selectionCriteria: SelectionCriteria): string {
    return selectionCriteria.years.length === 0 ? '' : `?years=${selectionCriteria.years}`;
  }

  public getHierarchyLevelPath(selectionCriteria: SelectionCriteria): string {
    if (selectionCriteria.hierarchyLevel === HierarchyLevel.AllPlans) {
      return '';
    } else {
      return `${baseUri[selectionCriteria.hierarchyLevel]}/${selectionCriteria.hierarchyLevelDetailId}`;
    }
  }
}
