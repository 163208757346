import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css'],
    standalone: false
})
export class ModalComponent implements OnInit {
  @Input() name: string;
  @Input() showClose: boolean;
  @Input() showHeader = true;
  @Input() showBody = true;
  @Input() showFooter = true;

  constructor() { }

  ngOnInit() {
  }

}
